import React from 'react';
import mail from '../assets/images/mail2.svg';

const ContactButton = () => {
  return (
    <a 
      className='contact-button'
      href="mailto:hello@mannani.fr"
    >
      <img src={mail} alt='écrivez-moi !'/>
    </a>
  )
}

export default ContactButton;